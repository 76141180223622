import {
  ComponentSettings,
  FieldTypes,
  empty,
  Field,
  Options,
} from "../settings/settings";
const field = {
  lastLogout: {
    type: FieldTypes.timestamp,
    text: {
      de: "Zuletzt ausgeloggt am",
      en: "Last logout",
      nl: "Laatste keer uitgelogd",
    },
    search: false,
  },
  update_user: {
    type: FieldTypes.value,
    text: { de: "Geändert von", en: "Update user", nl: "Gewijzigd door" },
    search: false,
  },
  update_time: {
    type: FieldTypes.timestamp,
    text: { de: "Geändert am", en: "Update time", nl: "Gewijzigd opb" },
    search: false,
  },
  favorite: {
    type: FieldTypes.value,
    text: { de: "Favoriten", en: "Favorites", nl: "Favorieten" },
    multiple: true,
    search: false,
  },
  _id: {
    type: FieldTypes.value,
    search: false,
    text: { de: "Name", en: "Name", nl: "Name" },
    multiple: false,
  },
};

export function getPublicFieldsMultiselect(fields: {
  [name: string]: Field;
}): Field {
  const options: Options = {};
  Object.keys(fields).forEach((name) => {
    const { text } = fields[name];
    options[name] = { text };
  });
  return {
    type: FieldTypes.options,
    text: { de: "Öffentliche Felder", en: "Public fields", nl: "Open velden" },
    multiple: true,
    options,
  };
}

const settings = {
  permission: {},
  list: {
    urls: [
      "profile.documentation",
      "profile.newCase",
      "profile.dinCodes",
      "profile.vehicles",
      "profile.vehiclesForm",
    ],
    boxesOnFilter: empty,
    fieldsOnFilter: empty,
    fields: empty,
  },
  url: {
    newCase:
      "https://www.obsolescence-management.net/wp-content/uploads/20200206_Verbesserung-der-Mitarbeit-im-Portal.pdf",
    dinCodes:
      "https://www.obsolescence-management.net/wp-content/uploads/DIN-Codes_15380-2.pdf",
    vehicles:
      "https://cdn.am-sys.com/assets/vehicles/Uebersicht%20Fahrzeuge%20der%20OM-Portal%20Mitglieder.xlsx",
    vehiclesForm:
      "https://cdn.am-sys.com/assets/vehicles/LEER%20-%20ZUM%20AUSFUELLEN%20-%20Uebersicht%20Fahrzeuge%20der%20OM-Portal%20Mitglieder.xlsx",
    defaultImage: "./assets/dummyProfile.png",
  },
  text: {
    title: { de: "Benutzerkonto", en: "User Account", nl: "Gebruikersaccount" },
    profile: { de: "Profil", en: "Profile", nl: "Profiel" },
    fields: { de: "Profil", en: "Profil", nl: "Profiel" },
    documentation: {
      de: "Bedienungsanleitung Obsoleszenz Management Portal (OMP)",
      en: "User Manual Obsolescence Management Portal (OMP)",
      nl: "Gebruiksaanwijzing Obsolescence Management Portal (OMP)",
    },
    newCase: {
      de: "Richtige Vorgehensweise beim Anlegen oder Kommentieren eines Falls",
      en: "Essential Content of a New Case",
    },
    dinCodes: { de: "DIN-Codes", en: "DIN-Codes", nl: "DIN-codes" },
    vehicles: { de: "Fahrzeuge", en: "Vehicles", nl: "Voertuigen" },
    vehiclesForm: {
      de: "Fahrzeuge (Formular)",
      en: "Vehicles (form)",
      nl: "Voertuigen (formulier)",
    },
    password: {
      de: "Passwort ändern",
      en: "Change password",
      nl: "Wachtwoord veranderen",
    },
    uploadImage: {
      de: "Profilbild hochladen",
      en: "Upload profile image",
      nl: "Profielfoto uploaden",
    },
    deleteImage: {
      de: "Profilbild löschen",
      en: "Delete profile image",
      nl: "Profielfoto verwijderen",
    },
    close: {
      de: "Profilansicht schließen",
      en: "Close profile view",
      nl: "Profielweergave sluiten",
    },
    notAllowed: {
      de: "Es ist Ihnen nicht erlaubt, auf das Konto anderer Benutzer zuzugreifen",
      en: "You are not allowed to access other users account",
    },
    userOMManual: {
      de: "Bedienungsanleitung Obsoleszenzmanagement (OM) Module",
      en: "User Manual Obsolescence Management (OM) Module",
      nl: "Gebruiksaanwijzing Obsolescence Management (OM) Module",
    },
    userRMManual: {
      de: "Bedienungsanleitung Risikomanagement (RM) Module",
      en: "User Manual Risk Management (RM) Module",
      nl: "Gebruiksaanwijzing Risk Management (RM) Module",
    },
    person: {
      de: "Person und Sichtbarkeit",
      en: "Person and Visibility",
      nl: "Persoon en Zichtbaarheid",
    },
    organisation: {
      de: "Organisation und Sichtbarkeit",
      en: "Organisation and Visibility",
      nl: "Organisatie en Zichtbaarheid",
    },
    settings: {
      de: "Einstellungen",
      en: "Settings",
      nl: "Instellingen",
    },
    help: {
      de: "Hilfreiche Informationen",
      en: "Helpful Information",
      nl: "Handige Informatie",
    },
    profileImg: {
      de: "Profilbild",
      en: "Profile Image",
      nl: "Profielbeeld",
    },
    profileInfo: {
      de: "Profil Informationen",
      en: "Profile Information",
      nl: "Profiel Informatie",
    },
    uploadImg: {
      de: "Bild hochladen",
      en: "Upload Image",
      nl: "Afbeelding uploaden",
    },
    deleteImg: {
      de: "Bild löschen",
      en: "Delete image",
      nl: "Beeld verwijderen",
    },
    language: {
      de: "Sprache ändern",
      en: "Change Language",
      nl: "Taal veranderen",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard Changes",
      nl: "Wijzigingen negeren",
    },
  },
  field,
};

export const profile: ComponentSettings<typeof settings> = settings;
