<!-- files for NS client -->
<div
  *ngIf="
    type !== 'post' &&
    type !== 'pcn' &&
    type != 'message' &&
    app.customers.expectCurrent === 'ns'
  "
>
  <div class="col-md-12 templatesHeading my-2 mx-1">
    {{ app.text.attachments.safetyEvaluationTemplates }}
  </div>
  <div class="col-md-12 ns-templates mb-3 p-1">
    <a 
      href="./assets/docs/Aanpak_OM_en_BTWM_proces.docx"
      target="_blank"
      >Aanpak_OM_en_BTWM_proces.docx</a
    >
  </div>
</div>
<div class="col-md-12" *ngIf="type !== 'thread'">
  <app-attachment
    *ngFor="let attachment of attachments"
    [type]="type"
    [id]="id"
    [editPermission]="editPermission"
    [fileName]="attachment"
    [fileDetails]="filesDetails[attachment]"
  ></app-attachment>
</div>
<div class="row" *ngIf="type === 'thread'">
  <div class="col-md-12">
    <div class="dropdown pull-right" *ngIf="tagsReady">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="dropdownMenuButtonTags"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        [ngbTooltip]="app.text.attachments.tagText"
      >
        <i class="fas fa-eye"></i>
        <span>&nbsp;{{ app.text.attachments.showMode }} &nbsp;</span>
        <span class="text-danger">
          {{ app.attachments.getTagText(app.attachments.viewMode) }}
        </span>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a
          class="dropdown-item pointer"
          *ngFor="let tag of app.attachments.caseTags"
          (click)="app.attachments.getAttachmentsByTag(tag)"
          [ngClass]="{
            lightBackground: tag === 'noTag'
          }"
        >
          {{ app.attachments.getTagText(tag) }}
        </a>
      </div>
    </div>
    <div class="dropdown pull-right" *ngIf="tagsReady">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="dropdownMenuButtonSortingOrder"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        [ngbTooltip]="app.text.attachments[app.attachments.sortingOrder]"
      >
        <span>&nbsp;{{ app.text.home.sort }}&nbsp;</span>
        <span class="text-danger">
          <i
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down':
                app.attachments.sortingOrder === 'descending',
              'fa-sort-amount-up': app.attachments.sortingOrder === 'ascending'
            }"
          ></i>

          {{ app.text.attachments.uploadTime }}
        </span>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div
          class="dropdown-item pointer"
          *ngIf="app.attachments.sortingOrder !== 'ascending'"
        >
          <i class="fas fa-sort-amount-up"></i>
          <a
            (click)="
              app.attachments.sortingOrder = 'ascending';
              app.attachments.sortAttachments('ascending')
            "
          >
            {{ app.text.attachments.uploadTime }}</a
          >
        </div>
        <div
          class="dropdown-item pointer"
          *ngIf="app.attachments.sortingOrder !== 'descending'"
        >
          <i class="fas fa-sort-amount-down"></i>
          <a
            (click)="
              app.attachments.sortingOrder = 'descending';
              app.attachments.sortAttachments('descending')
            "
          >
            {{ app.text.attachments.uploadTime }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 text-center p-1">
    <small
      *ngIf="
        attachments.length === 0 && app.thread.thread['thread.pcnSource'] === ''
      "
      >{{ app.text.app.noResults }}</small
    >
  </div>
</div>

<div
  class="col-md-12"
  *ngIf="
    (attachments.length > 0 || app.thread.thread['thread.pcnSource'] !== '') &&
    type === 'thread'
  "
>
  <div
    class="attachmentsText my-2 mx-1"
    *ngIf="type !== 'post' && type !== 'message'"
  >
    {{ app.text.attachments.allAttachments }}
  </div>
  <app-attachment
    *ngFor="let attachment of attachments"
    [type]="type"
    [id]="id"
    [editPermission]="editPermission"
    [fileName]="attachment"
    [fileDetails]="app.attachments.getAttachmentHistory(attachment)"
    [safetyEvaluationFileNS]="
      app.attachments.markSafetyEvaluationFile(attachment.id)
    "
  ></app-attachment>
</div>
<div
  class="col-md-12"
  *ngIf="type === 'thread' && app.thread.thread['thread.pcnSource'] !== ''"
>
  <div class="attachmentName p-1">
    <a [href]="app.thread.thread['thread.pcnSource']" target="_blank">{{
      app.field.getLabel(app.fieldId.alert.pcnSource)
    }}</a>
  </div>
</div>
