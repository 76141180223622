<app-modal
  #discardChangesModal
  [modalMessage]="app.text.part.discard"
  [modalTitle]="app.text.part.modalTitle"
  (ok)="discardChanges()"
></app-modal>
<app-create-manufacturer-modal
  #createManufacturer
  [modalMessage]="app.text.part.discard"
  [modalTitle]="app.text.part.modalTitle"
></app-create-manufacturer-modal>
<app-add-bom-item-modal
  #addBOMItem
  [modalMessage]="app.text.part.discard"
  [modalTitle]="app.text.part.modalTitle"
></app-add-bom-item-modal>
<app-history
  #historyModal
  [historyModalTitle]="app.text.history.partHistory"
></app-history>
<!-- this modal is used for detail-table items -->
<app-modal
  #deleteItemsModal
  [modalMessage]="deleteModalText"
  [modalTitle]="deleteModalTitle"
  (ok)="app.detailTable.deleteBOMItem(docType)"
>
</app-modal>

<div class="col-12 no-padding">
  <!-- sub-section buttons -->
  <div class="sub-title">
    <div class="control-bar">
      <button
        class="btn btn-control p-2"
        (click)="app.routing.closeTab()"
        [title]="app.text.thread.close"
      >
        <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.app.backToList }}
      </button>
    </div>
    <!-- part details -->
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="nested-wrapper1 pe-20">
          <div class="card custom-card my-3">
            <div class="card-header py-2">
              <span class="pt-2">
                {{ app.text.part.details }}
              </span>
              <!-- Part controls - edit -->
              <div class="float-end">
                <app-unlock
                  [id]="app.part.id"
                  [permission]="app.part.hasEditPermission()"
                >
                  <span
                    *ngIf="
                      app.part.modelHasChanges();
                      then thenBlock;
                      else elseBlock
                    "
                  >
                  </span>
                  <!-- then block - edit part and there are changes  -->
                  <ng-template #thenBlock>
                    <button
                      *ngIf="app.part.modelHasChanges()"
                      class="btn btn-sm btn-save"
                      (click)="
                        app.part.save(app.part.cleanPart, app.part.part);
                        app.field.inputSubject.next(true)
                      "
                    >
                      <i class="fa fa-check"></i> {{ app.text.app.save }}
                    </button>
                    <button
                      class="btn btn-sm btn-clean"
                      (click)="
                        discardChangesModal.open();
                        app.field.inputSubject.next(true)
                      "
                      [title]="app.text.part.discard"
                    >
                      <i class="fas fa-times close-icon"></i>&nbsp;
                      {{ app.text.post.cancel }}
                    </button>
                  </ng-template>
                  <!-- #else block - edit part and there are no changs  -->
                  <ng-template #elseBlock>
                    <button class="btn btn-sm btn-save-disabled">
                      <i class="fa fa-check"></i> {{ app.text.app.save }}
                    </button>
                    <button
                      class="btn btn-sm btn-clean"
                      (click)="
                        app.unlockedId = null; app.field.inputSubject.next(true)
                      "
                      [title]="app.text.part.close"
                    >
                      <i class="fas fa-times close-icon"></i>&nbsp;
                      {{ app.text.post.cancel }}
                    </button>
                  </ng-template>
                </app-unlock>
              </div>
            </div>
            <div class="border-top details-box py-1">
              <div class="ps-3">
                <div>
                  <span
                    >{{
                      app.field.getLabel(app.fieldId.part.partNumber)
                    }}:&nbsp;
                  </span>
                  {{ app.part.part["part.partNumber"] }}
                </div>
                <div>
                  <span>
                    {{
                      app.field.getLabel(app.fieldId.part.description)
                    }}:&nbsp;</span
                  >
                  {{ app.part.part["part.description"] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="controls-wrapper">
          <button
            *ngIf="app.unlockedId == null"
            class="btn btn-sm btn-clean my-3"
            (click)="historyModal.open()"
          >
            <i class="fas fa-archive"></i>&nbsp;
            {{ app.getText("part.history") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="wrapper mb-3">
    <!-- left side content-->
    <div class="nested-wrapper1">
      <div class="content-box">
        <!-- top menu navigation -->
        <div *ngIf="app.unlockedId == null">
          <app-left-nav [listBoxes]="boxesLeft" [showImage]="false">
          </app-left-nav>
        </div>

        <div *ngIf="app.unlockedId != null">
          <app-left-nav [listBoxes]="boxesLeftEditMode" [showImage]="false">
          </app-left-nav>
        </div>

        <!-- Part controls - edit -->
        <div class="nav-control-btn pull-right me-3">
          <span class="btn-group" *ngIf="app.unlockedId == null">
            <button
              class="btn btn-sm btn-clean"
              *ngIf="isExportAvailable()"
              (click)="app.RM.export('part', 'billOfMaterial'); app.table.selected.clear()"
            >
              <i class="fa fa-download"></i>&nbsp;
              {{ app.text.manufacturer.export }}
            </button>
            <button
              class="btn btn-sm btn-clean"
              *ngIf="isExportAvailable()"
              (click)="
                app.RM.export('part', 'substances'); app.table.selected.clear()
              "
            >
              <i class="fas fa-flask"></i>&nbsp;
              {{ app.text.RM.exportReach }}
            </button>
          </span>

          <span *ngIf="app.leftNav.selectedBox === 'part.manufacturerSection'">
            <button
              class="btn btn-sm btn-default-orange pull-right mb-1"
              *ngIf="app.table.selected.size > 0"
              (click)="deleteItems('manufacturer')"
            >
              <i class="fas fa-trash"></i> &nbsp;
              {{ app.text.part.remove }}
            </button>
            <button
              class="btn btn-sm btn-default-orange"
              (click)="
                app.state.createFromPart = false;
                createManufacturer.open();
                app.table.selected.clear()
              "
              [title]="app.text.manufacturer.createNewManufacturer"
              *ngIf="app.unlockedId == null"
            >
              <i class="fas fa-plus"></i>
              &nbsp;<span>
                {{ app.text.app.add }}
              </span>
            </button>
          </span>
        </div>

        <!-- Part content-->
        <div
          *ngIf="
            app.leftNav.selectedBox !== 'part.billOfMaterialSection' &&
            app.leftNav.selectedBox !== 'part.manufacturerSection'
          "
          class="mb-3"
        >
          <div class="box-content">
            <app-box
              [box]="app.leftNav.selectedBox"
              [model]="app.part.part"
              [unlock]="app.part.id"
              [showHeader]="false"
            >
            </app-box>
          </div>
        </div>
        <div
          class="bg-white"
          *ngIf="app.leftNav.selectedBox === 'part.billOfMaterialSection'"
        >
          <div>
            <button
              class="btn btn-sm btn-default-orange pull-right me-3 mb-1"
              *ngIf="app.table.selected.size > 0"
              (click)="deleteItems('part')"
            >
              <i class="fas fa-trash"></i> &nbsp;
              {{ app.text.part.remove }}
            </button>
            <button
              class="btn btn-sm btn-default-orange pull-right mx-3 mb-1"
              (click)="
                app.state.createFromPart = true;
                addBOMItem.open();
                app.table.selected.clear()
              "
              [title]="'Add new item in bill of materials'"
              *ngIf="app.unlockedId == null"
            >
              <i class="fas fa-plus"></i>
              &nbsp;<span>
                {{ app.text.app.add }}
              </span>
            </button>
          </div>
          <div class="table-bkgd clear">
            <app-detail-table
              [columns]="app.list.part.billOfMaterialSection"
              [showPaginator]="false"
              [showNumberingColumn]="true"
              [type]="'part'"
            ></app-detail-table>
          </div>
        </div>
        <div *ngIf="app.leftNav.selectedBox === 'part.manufacturerSection'">
          <div class="table-bkgd">
            <app-detail-table
              [columns]="app.list.part.manufacturerSection"
              [showPaginator]="false"
              [type]="'manufacturer'"
            >
            </app-detail-table>
          </div>
        </div>
      </div>
    </div>

    <!-- right side content-->
    <div class="nested-wrapper2">
      <!-- Image box -->
      <div class="image-box">
        <div class="card my-3">
          <div class="card-header d-flex justify-content-between">
            {{ app.text.part.image }}
            <input
              #fileInput
              type="file"
              accept="image/*"
              style="display: none"
              (change)="onUpload()"
            />
            <button
              class="btn btn-sm"
              (click)="fileInput.click()"
              [title]="app.text.thread.uploadImage"
            >
              <i class="fal fa-file-alt icon-orange"></i>
            </button>
          </div>
          <app-left-nav
            [imageSource]="app.part.externalImage"
            [showBoxes]="false"
            [showImageControlBtn]="app.part.externalImage != ''"
            [docType]="'part'"
            [file]="app.part.getImage()"
          >
          </app-left-nav>
        </div>
      </div>

      <!-- messages -->
      <div class="message-box">
        <app-box
          [box]="'rm-messages'"
          [model]="app.part.part"
          [unlock]="app.part.id"
        ></app-box>
      </div>
    </div>
  </div>
</div>
