import { ComponentSettings, empty, FieldTypes } from "../settings/settings";
import { getPublicFieldsMultiselect } from "./profile.settings";

const field = {
  "Titel Vorname Nachname": {
    type: FieldTypes.value,
    text: {
      de: "Vorname",
      en: "First Name",
      nl: "Voornaam",
    },
  },
  LastName: {
    type: FieldTypes.value,
    text: {
      de: "Nachname",
      en: "Last name",
      nl: "Achternaam",
    },
  },
  "Position/Funktion": {
    type: FieldTypes.value,
    text: {
      de: "Position/Funktion",
      en: "Position/Function",
      nl: "Positie/Functie",
    },
  },
  "Abteilung/Bereich": {
    type: FieldTypes.value,
    text: { de: "Abteilung/Bereich", en: "Department", nl: "Afdeling" },
  },
  Adresse: {
    type: FieldTypes.value,
    text: { de: "Adresse", en: "Address", nl: "Adres" },
  },
  "PLZ Ort": {
    type: FieldTypes.value,
    text: { de: "PLZ Ort", en: "Postcode City", nl: "Postcode Stad" },
  },
  Land: {
    type: FieldTypes.value,
    text: { de: "Land", en: "Country", nl: "Land" },
  },
  Telefon: {
    type: FieldTypes.value,
    text: { de: "Telefon", en: "Telephone", nl: "Telefoon" },
  },
  Handy: {
    type: FieldTypes.value,
    text: { de: "Handy", en: "Mobile phone", nl: "Mobiel" },
  },
  Fax: { type: FieldTypes.value, text: { de: "Fax", en: "Fax", nl: "Fax" } },
  Email: {
    type: FieldTypes.value,
    text: { de: "Email", en: "Email", nl: "e-mail" },
  },
};

const settings = {
  permission: {
    see: false,
  },
  list: {
    personProfile: empty,
    columns: empty,
  },
  url: {},
  text: {
    personProfile: {
      de: "Person",
      en: "Person",
      nl: "Persoon",
    },
  },
  field: {
    ...field,
    publicFields: getPublicFieldsMultiselect(field),
  },
};

export const personProfile: ComponentSettings<typeof settings> = settings;
