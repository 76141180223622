import { UsersServiceType } from "./users.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { hasChanges } from "../utils/app.utils";
import { Injectable, Inject } from "@angular/core";
import {
  deleteUser,
  doc2Model,
  getComponentResponsibles,
  getCurrentUser,
  getUsersCompressed,
  getUsersWithRoles,
  model2Doc,
  saveNewUser,
  setUserLanguage,
  updateUser,
} from "../api.service";
import { User } from "../../../../shared/models/user";
import { checkPasswordPolicy } from "../password/password-policy";
import { Customer } from "../../../../shared/types/customers";
import { environment } from "../../environments/environment";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { writeConfigFile } from "../export/config-export";

@Injectable()
export class UsersService implements UsersServiceType {
  users: User[] = [];
  userIds: string[] = [];
  cleanUsers: User[] = [];
  currentUser = new User();
  currentUserModel: any = {} as User;
  cleanUserModel: any = {} as User;
  currentId: string = "";
  currentUserName: string = "";
  isNew: boolean = false;
  componentResponsibles: string[] = [];
  userRoles: [] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getListOfUsers() {
    this.users = await getUsersCompressed(this.app.customers.expectCurrent);
    this.userIds = this.users.map((user) => user.id);
    this.cleanUsers = this.users;
  }

  async getUserById(userId: string) {
    this.currentUser = await getCurrentUser(
      this.app.customers.expectCurrent,
      userId
    );
    this.currentId = this.currentUser.name;
    this.currentUserModel = doc2Model("user", this.currentUser);
    this.cleanUserModel = doc2Model("user", this.currentUser);
    return this.currentUser;
  }

  async getComponentResponsibles() {
    if (this.app.customers.expectCurrent !== Customer.DB) {
      return;
    }

    this.componentResponsibles = await getComponentResponsibles(
      this.app.customers.expectCurrent
    );
  }

  async getUsersWithRoles() {
    this.userRoles = await getUsersWithRoles(this.app.customers.expectCurrent);
  }

  getUserRoles(userName: string) {
    let roles: string[] = [];
    let user: any = this.userRoles.find((user: any) => user.name === userName);
    user.roles?.forEach((role: string) => {
      roles.push(role.split("-")[1]);
    });
    return roles;
  }

  modelHasChanges() {
    const userChanges = hasChanges(this.cleanUserModel, this.currentUserModel);
    const userSettingChanges = hasChanges(
      this.app.userSettings.cleanUserEmailPreferences,
      this.app.userSettings.currentUserEmailPreferences
    );

    return userChanges || userSettingChanges;
  }

  getOptions(role?: string, jobTitle?: string) {
    let currentRole = this.app.customers.expectCurrent + "-" + role;
    let isProductionMode = environment.production;
    let users: any[] = [];
    if (this.userRoles.length > 0) {
      if (currentRole !== undefined && jobTitle == undefined) {
        users = this.userRoles.filter((user: any) =>
          user.roles.includes(currentRole)
        );
      } else {
        users = this.userRoles.filter(
          (user: any) =>
            user.jobTitle != null && user.jobTitle.includes(jobTitle)
        );
      }
    }

    let result: string[] = [];
    if (isProductionMode) {
      /** https://app.asana.com/0/1203066793253695/1199525579744726  */
      /** do not show amsys users in dropdowns in production mode */
      result = users
        .filter((user: any) => !user.name.toLowerCase().includes("amsys"))
        .map((result) => result.name);
      // !user.email.includes("@am-sys.com")
    } else {
      result = users.map((result) => result.name);
    }
    return result as any;
  }

  checkPasswordPolicy(user: User) {
    const result = checkPasswordPolicy(this.app.text, user);

    if (result !== "No error") {
      this.app.hasError = true;
      this.app.errorText = result;
    }

    return result;
  }

  async save() {
    this.app.hasError = false;
    let result;

    const userDoc = model2Doc("user", this.currentUserModel);
    const checkUserExists = this.users.findIndex(
      (user: User) => user.name === userDoc.name
    );
    const passwordPolicy = StringUtils.isNullOrEmpty(userDoc["password"])
      ? "No error"
      : this.checkPasswordPolicy(userDoc);

    if (this.isNew) {
      // Create user
      if (checkUserExists !== -1) {
        this.app.hasError = true;
        this.app.errorText = this.app.text.user.userAlreadyExists;
        this.app.hasSuccess = false;
      } else {
        const userNamePloicy = this.checkUsername(userDoc.name);
        userDoc.name = userDoc.name.trim();

        if (passwordPolicy === "No error" && userNamePloicy === "No error") {
          result = await saveNewUser(this.app.customers.expectCurrent, userDoc);
        }
      }
    } else {
      // Update user
      if (passwordPolicy === "No error") {
        userDoc.name = userDoc.name.trim();
        result = await updateUser(this.app.customers.expectCurrent, userDoc);
      } else {
        return;
      }
    }

    if (!result.error) {
      this.app.hasSuccess = true;

      if (this.isNew) {
        this.app.successText = this.app.text.user.successUserCreated;
        this.app.routing.navigateUser(userDoc.name);
      } else {
        this.app.successText = this.app.text.user.succesUserUpdated;
        this.currentUserModel = doc2Model("user", result);
        this.cleanUserModel = doc2Model("user", result);
      }

      this.app.unlockedId = null;
      setTimeout(() => {
        this.app.hasSuccess = false;
      }, 3000);
    } else {
      this.app.hasSuccess = false;
      this.app.hasError = true;
      this.app.errorText = this.app.text.user.passwordUsed;
    }
    await this.app.userSettings.updateSettings();
  }

  setPasswordExpireDate() {
    // Set passwordExpireDate 6 months starting from today
    if (this.currentUserModel == null) {
      return;
    }

    const expiryDate = this.currentUserModel["user.passwordExpireDate"];
    const d = new Date();
    d.setMonth(d.getMonth() + 6);
    const nextExpireDate = d.toISOString().slice(0, 10);

    if (!this.currentUserModel["user.passwordNeverExpire"]) {
      if (
        (!this.isNew &&
          !StringUtils.isNullOrEmpty(this.currentUserModel["user.password"])) ||
        StringUtils.isNullOrEmpty(expiryDate)
      ) {
        this.currentUserModel["user.passwordExpireDate"] = nextExpireDate;
      }
    }
  }

  /**
   * Store the user's preferred language in the user document
   * @param language The language to be stored
   */
  async setLanguage(language: string): Promise<void> {
    if (this.app.user != null) {
      await setUserLanguage(this.app.user, language);
    }
    this.app.header.getHeaderTitle(this.app.state.inboxType);
  }

  async deactivateUser() {
    this.app.unlockedId = this.currentUser.name;
    this.currentUserModel[this.app.fieldId.user.active] = false;
    await this.save();
  }

  async delete() {
    const customer = this.app.customers.expectCurrent;
    const doc: User = this.currentUser;
    await deleteUser(customer, doc.name);

    if (this.currentUser.name === this.app.user) {
      this.app.auth.logout();
    }
    this.app.routing.navigateUsers();
  }

  isDisabled() {
    const requiredFields = this.app.field.getInvalidFields(
      "user",
      this.currentUserModel
    );

    if (!this.isEditMode()) {
      return true;
    }

    if (requiredFields.length === 0 && this.isNew) {
      return false;
    }

    if (requiredFields.length === 0 && this.modelHasChanges()) {
      return false;
    } else {
      return true;
    }
  }

  hasEditPermission() {
    const { permission } = this.app;

    if (permission.user.manage) {
      return true;
    }
    return false;
  }

  setRequiredFields() {
    const fieldId = this.app.fieldId.user.name;
    this.app.field.resetAllRequiredFieldSettings("user");
    if (!this.isNew) {
      this.app.field.getFieldSettings(fieldId).mutable = false;
      this.app.field.getFieldSettings("user.password").required = false;
      this.app.field.getFieldSettings("user.retypePassword").required = false;
      this.app.field.getFieldSettings("user.email").mutable = false;
      this.app.field.getFieldSettings("user.roles").required = true;
    } else {
      this.app.field.getFieldSettings(fieldId).mutable = true;
      this.app.field.getFieldSettings("user.name").required = true;
      this.app.field.getFieldSettings("user.email").required = true;
      this.app.field.getFieldSettings("user.roles").required = true;
      this.app.field.getFieldSettings("user.password").required = true;
      this.app.field.getFieldSettings("user.retypePassword").required = true;
    }
  }

  async exportData() {
    await writeConfigFile(this.app, "user");
  }

  private checkUsername(username: string) {
    const format: RegExp = /[!^()\=\[\]{};':"\\|<>\/?@#$%*]+/;
    if (StringUtils.containsSpecialCharacter(username, format)) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.user.usernameNotAccepted;
      return this.app.text.user.usernameNotAccepted;
    } else {
      return "No error";
    }
  }

  private isEditMode() {
    if (this.app.unlockedId === this.currentId) {
      return true;
    }
    if (this.isNew) {
      return true;
    }
    return false;
  }
}
