import { Component, OnInit, Output, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { ResetPasswordObject } from "../auth/auth.service.type";
import { ActivatedRoute } from "@angular/router";
import { checkPasswordPolicy } from "../password/password-policy";
import { DocModel } from "../state/state";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import {
  checkIfTokenHasBeenUsed,
  checkIfTokenValid,
  doc2Model,
  model2Doc,
} from "../api.service";
import { ResetDetails } from "../../../../shared/models/reset-details";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild("content", { static: true }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  model: DocModel = {};
  routeParams: any;
  tokenExpired: boolean = false;
  tokenHasBeenUsed: boolean = false;
  hasSuccess: boolean = false;

  constructor(private route: ActivatedRoute, private modalService: NgbModal) {
    this.routeParams = this.route.snapshot.params;
  }
  async ngOnInit() {
    if (this.routeParams.token != null && this.routeParams.token !== "") {
      this.tokenExpired = await checkIfTokenValid(this.routeParams.token);
      this.tokenHasBeenUsed = await checkIfTokenHasBeenUsed(
        this.routeParams.token
      );
      if (this.tokenExpired || this.tokenHasBeenUsed) {
        setTimeout(() => {
          this.app.routing.navigateLogin();
        }, 3000);
      }
    }
  }

  async open() {
    try {
      // this.model = this.app.seFilter.setSeFields();
      this.hasSuccess = false;
      this.app.field.isValidRegex("user.email", this.model["user.email"]);
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
        backdrop: "static",
        keyboard: false,
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  get requiredFields() {
    this.app.field.resetAllRequiredFieldSettings("user");
    this.app.field.getFieldSettings("user.name").required = true;
    this.app.field.getFieldSettings("user.email").required = true;
    this.app.field.getFieldSettings("user.email").mutable = true;
    if (this.app.view === "home") {
      this.app.field.getFieldSettings("user.password").required = true;
    }
    let requiredFields = this.app.field.getInvalidFields(
      "user",
      this.app.model
    );
    return requiredFields;
  }

  async requestChangePass() {
    let checkEmail = this.validateEmail();
    if (checkEmail) {
      let doc: ResetDetails = {
        name: this.model["user.name"].trim(),
        email: this.model["user.email"].toLowerCase(),
      };
      try {
        this.hasSuccess = true;
        await this.app.auth.resetPassword(doc);
        this.app.hasSuccess = true;
        this.app.successText = this.app.text.resetPassword.messageSent;
        setTimeout(() => {
          this.app.state.next({ hasSuccess: false, errorText: "" });
        }, 3000);
      } catch (error) {
        this.app.hasError = true;
        this.app.errorText = this.app.text.resetPassword.userNotFound;
        setTimeout(() => {
          this.app.state.next({ hasError: false, errorText: "" });
        }, 3000);
      }
    } else {
      this.app.hasError = true;
      this.app.errorText = this.app.text.resetPassword.emailNotCorrect;
      setTimeout(() => {
        this.app.state.next({ hasError: false, errorText: "" });
      }, 3000);
    }
    this.model = {};
    this.modalService.dismissAll();
  }

  validateEmail() {
    var re = /\S+@\S+\.\S+/;
    return re.test(this.model["user.email"]);
  }

  async changePassword() {
    const docPass = model2Doc("user", this.model);
    let checkPassword = checkPasswordPolicy(this.app.text, docPass);
    if (checkPassword !== "No error") {
      this.app.hasError = true;
      this.app.errorText = checkPassword;
    } else {
      let doc: ResetPasswordObject = {
        password: this.model["user.password"],
        retypePassword: this.model["user.retypePassword"],
        token: this.routeParams.token,
      };
      let result = await this.app.auth.savePassword(doc);
      if (result) {
        this.app.hasSuccess = true;
        this.app.successText = "User saved succesfully!";
        this.app.routing.navigateLogin();
      } else {
        this.app.hasError = true;
        this.app.errorText = "Can not change password!";
      }
    }
  }
}
