<div class="filter-table" [ngSwitch]="fieldType">
  <div *ngSwitchCase="'options'">
    <div
      class="dropdown"
      [ngClass]="{
        'background-color': app.filter.isSelected(
          fieldId,
          app.filterHeaderTable.filters
        )
      }"
    >
      <input
        class="form-control dropdown-filter dropdown-toggle"
        data-bs-toggle="dropdown"
        onkeydown="return false"
        placeholder="{{
          app.filter.isSelected(fieldId, app.filterHeaderTable.filters)
            ? app.text.RM.filterApplied
            : app.text.RM.filter
        }}"
        (click)="app.filterTable.isDropdownOpen = true"
      />
      <button class="btn clear-dropdown">
        <i
          *ngIf="app.filter.isSelected(fieldId, app.filterHeaderTable.filters)"
          class="fa fa-times icon-size"
          (click)="clearFilter(fieldId)"
        ></i>
      </button>
      <div class="dropdown-menu dropdown-list">
        <div *ngFor="let option of app.filterTable.getOptions(fieldId)">
          <input
            #checkboxes
            type="checkbox"
            class="checkbox-input"
            value="{{ option }}"
            [checked]="
              app.filter.isChecked(
                fieldId,
                option,
                app.filterHeaderTable.filters
              )
            "
            (click)="app.filterHeaderTable.filter(fieldId, option)"
          />
          <span class="label">{{
            app.filterTree.getOptionText(fieldId, option)
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- when the type of the field is input -->
  <div *ngSwitchCase="'value'">
    <div
      class="input-group"
      [ngClass]="{
        'background-color': model[fieldId] != null && model[fieldId] !== ''
      }"
    >
      <input
        type="text"
        class="form-control input-filter"
        [(ngModel)]="model[fieldId]"
        (keydown)="checkKey($event, fieldId, model[fieldId])"
        placeholder="{{ app.text.RM.filter }}"
        (click)="app.filterTable.isDropdownOpen = false"
      />

      <button
        class="btn btn-sm p-1"
        *ngIf="model[fieldId]"
        (click)="clearFilter(fieldId)"
      >
        <i class="fa fa-times"></i>
      </button>

      <button class="btn btn-sm p-1">
        <i
          class="fa fa-search"
          (click)="app.filterHeaderTable.filter(fieldId, model[fieldId])"
        ></i>
      </button>
    </div>
  </div>

  <div *ngSwitchCase="'typeahead'">
    <div
      class="input-group"
      [ngClass]="{
        'background-color': model[fieldId] != null && model[fieldId] !== ''
      }"
    >
      <app-field
        [model]="model"
        [field]="fieldId"
        [showLabel]="false"
        [showMandatory]="true"
        [typeaheadPlaceholder]="app.text.RM.filter"
      >
      </app-field>
      <!-- <app-field
        *ngIf="field === app.fieldId.part.partNumber"
        [model]="model"
        [field]="app.fieldId.part.partNumber"
        [showLabel]="false"
        [showMandatory]="true"
        [typeaheadPlaceholder]="app.text.RM.filter"
      >
      </app-field>
      <app-field
        *ngIf="field === app.fieldId.manufacturer.partNumber"
        [model]="model"
        [field]="app.fieldId.manufacturer.partNumber"
        [showLabel]="false"
        [showMandatory]="true"
        [typeaheadPlaceholder]="app.text.RM.filter"
      >
      </app-field>
      <app-field
        *ngIf="field === app.fieldId.manufacturer.manufacturerPartNumber"
        [model]="model"
        [field]="app.fieldId.manufacturer.manufacturerPartNumber"
        [showLabel]="false"
        [showMandatory]="true"
        [typeaheadPlaceholder]="app.text.RM.filter"
      >
      </app-field> -->
    </div>
  </div>

  <div *ngSwitchDefault></div>
</div>
