import { AppType, APP_TYPE } from "../app.type";
import { getNeighbour } from "../utils/app.utils";
import { TableServiceType } from "./table.service.type";
import { Injectable, Inject } from "@angular/core";
import { bulkDocsByType } from "../api.service";
import { Type } from "../../../../shared/components";
import { Thread } from "../../../../shared/models/thread";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";
@Injectable()
export class TableService implements TableServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  selected = new Set<any>();
  docs = new Set<any>();
  type: Type | any = "";
  action: string | undefined = "";
  result: Partial<Thread>[] = [];

  get sortField() {
    return this.app.state.sortField;
  }

  set sortField(sortField: string | null) {
    if (
      sortField === "thread.update_time" ||
      sortField === "thread.omfNumber"
    ) {
      this.toggleSortDirection(sortField);
      this.result = this.app.thread.threadsCompressed.filter(
        (t: Partial<Thread>) => this.app.thread.ids.includes(t._id)
      );
      this.sortDirection = -1;
    }
    this.app.state.next({ sortField });
  }

  get sortDirection() {
    return this.app.state.sortDirection;
  }

  set sortDirection(sortDirection: -1 | 1) {
    this.app.state.next({ sortDirection });
    /** exception for home where the filtering is done on a different type of object */
    if (this.app.view === "home") {
      let docs: Partial<Thread>[] = [];
      docs = this.app.thread.threadsCompressed.filter((t: Partial<Thread>) =>
        this.app.thread.ids.includes(t._id)
      );
      this.sortDocs(docs);
    }
  }

  sort(data: any, type?: string) {
    let { sortField, sortDirection } = this;

    if (!StringUtils.isNullOrEmpty(type)) {
      if (type === "train") {
        // train series table needs to be sorted by create_time
        sortField = `${type}.create_time`;
        sortDirection = 1;
      } else {
        // when export data from Parts/Manufacturers table, sort the items by CPN
        sortField = `${type}.partNumber`;
        sortDirection = 1;
      }
    }

    if (sortField == null) {
      return;
    }
    let option = sortField.split(".")[1];

    let fieldType = this.app.field.getType(sortField);

    if (data === undefined) {
      return;
    }

    data.sort((a: any, b: any) => {
      let aValue = a[option];
      let bValue = b[option];

      // used for documents where the taskCompleted property has not been set (task status is Open)
      if (option === "taskCompleted") {
        if (aValue == null) {
          a[option] = false;
        }
        if (bValue == null) {
          b[option] = false;
        }
      }

      if (fieldType === "options") {
        // aValue and bValue are values of an option field
        if (sortField == null) {
          return;
        }
        aValue = this.app.field.getFieldValueAsText(sortField, a[option]);
        bValue = this.app.field.getFieldValueAsText(sortField, b[option]);
      }

      if (aValue != null && bValue != null) {
        // aValue and bValue are string of numbers
        const [nA, nB] = [aValue, bValue].map(Number);
        if (!Number.isNaN(nA) && !Number.isNaN(nB)) {
          return (nA - nB) * sortDirection;
        }

        // aValue and bValue are strings
        if (typeof aValue === "string" && typeof bValue === "string") {
          aValue = (aValue as string).toLowerCase().trim();
          bValue = (bValue as string).toLowerCase().trim();
        }
      }

      if (aValue == null) aValue = "";
      if (bValue == null) bValue = "";

      if (aValue === bValue) {
        return 0;
      }
      if (aValue < bValue) {
        return -sortDirection;
      }
      return sortDirection;
    });
    return data;
  }

  sortDocs(data: any) {
    const { sortField, sortDirection } = this;
    if (sortField == null || data[0] == null) {
      return;
    }
    let option = sortField.split(".")[1];

    let index = this.app.thread.ids.findIndex((d) => d === data[0]._id);

    this.app.thread.ids.splice(index, data.length);

    let fieldType = this.app.field.getType(sortField);

    data.sort((a: any, b: any) => {
      let aValue = a[option];
      let bValue = b[option];
      if (fieldType === "options") {
        aValue = this.getValueOfTheFieldBasedOnType(sortField, a[option]);
        bValue = this.getValueOfTheFieldBasedOnType(sortField, b[option]);
      }
      if (aValue != null && bValue != null) {
        if (typeof aValue === "string" && typeof bValue === "string") {
          aValue = (aValue as string).toLowerCase().trimLeft();
          bValue = (bValue as string).toLowerCase().trimLeft();
        }
      }

      if (
        this.sortField === "thread.omfNumber" &&
        (this.app.customers.expectCurrent === Customer.CEOTRONICS ||
          this.app.customers.expectCurrent === Customer.SCHIEBEL)
      ) {
        if (sortDirection === 1) {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      } else {
        if (aValue == null) aValue = "";
        if (bValue == null) bValue = "";

        if (aValue === bValue) {
          return 0;
        }
        if (aValue < bValue) {
          return -sortDirection;
        }
        return sortDirection;
      }
    });

    this.app.thread.ids = data.map((t: Partial<Thread>) => t._id);
    return data;
  }

  toggleSortDirection(sortField: string) {
    if (!this.canColumnBeSorted(sortField)) {
      return;
    }
    if (sortField !== this.sortField) {
      this.app.state.next({ sortField, sortDirection: 1 });

      this.sortDirection = 1;
      return;
    }
    const { sortDirection } = this.app.state;

    if (sortDirection === 1) {
      this.app.state.next({ sortDirection: -1 });

      this.sortDirection = -1;
      return;
    }
    if (sortDirection === -1) {
      this.app.state.next({ sortDirection: 1 });

      this.sortDirection = 1;

      return;
    }
  }

  toggleSortField(sortField: string) {
    if (sortField !== this.sortField) {
      this.app.state.next({ sortField, sortDirection: 1 });
      return;
    }

    const { sortDirection } = this.app.state;
    if (sortDirection === 1) {
      this.app.state.next({ sortDirection: -1 });
      return;
    }

    this.app.state.next({ sortField: null });
  }

  getNext<T>(current: T, array: T[]) {
    return <any>getNeighbour(current, array, 1);
  }

  getPrevious<T>(current: T, array: T[]) {
    return <any>getNeighbour(current, array, -1);
  }

  getColumnType(column: string): string {
    switch (column) {
      case "obsolescenceStatus":
      case "obsolescenceStatus2years":
      case "obsolescenceStatus4years":
      case "obsolescenceStatus6years":
      case "obsolescenceStatus8years":
      case "impact":
      case "likelihood":
      case "totalRisk":
      case "euRoHS":
      case "reachAffected":
      case "euRoHSStatus":
      case "oldSeStatus":
      case "statusChange":
      case "leadTime":
      case "lifecycleCode":
        return "badge";
      case "environmentalLink":
      case "materialDeclaration":
      case "pcnSource":
      case "datasheet":
      case "distributor":
        return "link";
      case "estimatedEOLDate":
      case "estimatedYearsToEOL":
      case "ltbDate":
      case "euRoHSVersion":
      case "maxWeeks":
      case "minWeeks":
      case "sources":
      case "averagePrice":
        return "textCenter";
      case "partNumber":
        return "partNumber";
      case "manufacturerPartNumber":
        return "manufacturerPartNumber";
      case "active":
        return "preferred";
      case "threadCase":
      case "createThread":
        return "create";
      case "matchedSystem":
        return "filterType";
      case "delete":
        return "delete";
      case "severity":
        return "severity";
      case "checkedByOm":
        return "seen";
      case "typeOfChange":
        return "typeOfChange";
      case "valid":
        return "valid";
      // case "reachSubstances":
      //   return "smallCell";
      case "productCategory":
        return "option";
      case "obsolescenceManagement":
        return "radio";
      case "stockRange":
        return "stockRange";
      case "supplierPending":
        return "pending";
      case "activeMPNs":
        return "activeMPNs";
      default:
        return "normal";
    }
  }

  /** decide how the cell is going to be printed out
   * eg: if column is o type date, return time type, and it can be used as general string interpolation
   * to avoid to many *ngIf's on the html
   */
  getColumnTypeDisplay(column: string) {
    switch (column) {
      case "fav":
        /** special logic needed for thread.fav - display of stars*/
        return "fav";

      case "descr":
      case "items":
      case "vehicleNames":
      case "taskDescription":
      case "changeClasses":
        /** limit - for being able to use the app-limit component */
        return "limit";

      case "update_time":
      case "closed_on":
      case "create_time":
      case "change_time":
        /** to be able to use the  filter '| date: "yyyy-MM-dd HH:mm:ss"'  */
        return "time";

      /** date format without time
       */
      case "itemLTD":
      case "issueDate":
      case "effectiveDate":
        return "date";

      case "omfStatus":
      case "priority":
      case "dinText":
      case "statusRisk":
      // case "euRoHSStatus":
      case "change_type":
      case "taskCompleted":
      case "criticality":
      case "productCategory":
      case "catComplexity":
      case "itemType":
      case "category":
      case "projectNumber":
        /** to be able to uset the   app.field.getOptionText(column)*/
        return "optionText";

      case "dueDate":
      case "taskDueDate":
      case "closingDate":
        /** special logic needed to display the dueDates - getDueDateStyle()/getTaskDueDateColor() */
        return "dueDate";
      /** special logic needed for displaying of the checkboxes, to be added more  */
      case "entryCheckbox":
      case "typeOfPost":
      case "active":
      case "valid":
        return "checkBox";

      /** display of differents types of actions buttons at the end of the table  */
      case "actions":
      case "createManufacturer":
      case "delete":
      case "checkedByOm":
      case "createCase":
      case "threadCase":
      case "select":
      case "seen":
      case "remove":
      case "assignedTo":
        return "actions";
      /** handle different display for DB - status 90 - cargo */
      case "Cargo":
        return "cargo";

      /** if the column needs to be displayed as a link */
      case "datasheet":
      case "pcnSource":
      case "materialDeclaration":
      case "environmentalLink":
      case "distributor":
        return "link";

      case "partsToVehicles":
        return "partsToVehicles";

      case "typeOfChange":
      case "alertCategory":
        // case "changeClasses":
        return "getFieldValueAsText";

      case "oldSeStatus":
      case "statusChange":
      case "severity":
      case "leadTimeFrom":
      case "leadTimeTo":
      case "leadTime":
      case "likelihood":
      case "impact":
      case "totalRisk":
      case "obsolescenceStatus":
      case "obsolescenceStatus2years":
      case "obsolescenceStatus4years":
      case "obsolescenceStatus6years":
      case "obsolescenceStatus8years":
      case "euRoHS":
      case "euRoHSStatus":
      case "reachAffected":
      case "impact":
        return "badge";
      case "omfNumber":
      case "partNumber":
      case "manufacturerPartNumber":
        /** to be able to set a click action on the table cell*/
        let typeOfField = "navigateAction";
        /** we need redirect on the omfNumber only from the tasks */
        if (this.app.view !== "tasks" && column === "omfNumber") {
          typeOfField = "normal";
        }
        return typeOfField;
      case "obsolescenceManagement":
      case "active":
        return "radio";
      case "artNumber":
        /** used in where used table to display the upside down tree */
        return "artNumber";
      default:
        return "normal";
    }
  }

  canColumnBeSorted(column: string) {
    switch (column) {
      case "createManufacturer":
      case "datasheet":
      case "createCase":
      case "select":
      case "thread.fav":
      case "thread.vehicleNames":
      case "delete":
      case "valid":
      case "impact.actions":
      case "part.partsToVehicles":
      case "part.threadCase":
      case "assignedTo":
        return false;
      default:
        return true;
    }
  }

  canColumnBeResized(column: string) {
    switch (column) {
      case "threadCase":
      case "matchedSystem":
      case "environmentalLink":
      case "materialDeclaration":
      case "link":
      case "create":
      case "select":
      case "seen":
      case "partsToVehicles":
      case "change.seen":
      case "alert.checkedByOm":
      case "createCase":
      case "createManufacturer":
      case "datasheet":
      case "part.partsToVehicles":
      // case "part.partNumber":
      case "part.threadCase":
      case "part.matchedSystem":
      case "part.statusAlerts":
      case "assignedTo":
        return false;
      default:
        return true;
    }
  }

  getSizeOfColumn(column: string) {
    switch (column) {
      case "partNumber":
      case "manufacturerPartNumber":
        return "lg-col";
      default:
        return "default-col";
    }
  }

  selectAll(docs: any[], all?: boolean) {
    if (all) {
      docs.forEach((doc) => this.selected.add(doc));
    } else {
      this.app.paginator.getPage(docs).forEach((doc) => this.selected.add(doc));
    }
    return this.selected;
  }

  clearSelection() {
    this.selected = new Set();
    return this.selected;
  }

  toggleSelect(doc: any) {
    if (this.selected.has(doc)) {
      this.selected.delete(doc);
    } else {
      this.selected.add(doc);
    }
    return this.selected;
  }

  prepareDocsToUpdate(docs: any, type: Type, action?: string) {
    // this.docs.add(docs);
    this.docs = this.selected;
    this.type = type;
    this.action = action;
    if (this.action === "seen") {
      this.docs.forEach((doc) => {
        doc.seen = true;
        doc.update_user = this.app.user;
        doc.update_time = new Date().getTime();
      });
      this.docs;
      this.updateDocs();
      if (docs.type === "alert") {
        this.app.alerts.getAlerts(this.app.mail.tab);
        this.app.alerts.getUnreadAlerts();
      } else {
        // location.reload();
      }
    }
    if (this.action === "delete") {
      this.docs.forEach((doc) => {
        doc._deleted = true;
      });
    }
    return this.docs;
  }

  async updateDocs() {
    this.app.spinner.showSpinner();
    await bulkDocsByType(
      this.app.customers.expectCurrent,
      this.type,
      Array.from(this.docs)
    );
    this.docs.clear();
    this.app.spinner.hideSpinner();
    this.type = "";
    this.action = "";
  }

  getValueOfTheFieldBasedOnType(field: string, value: string) {
    switch (field) {
      case "thread.omfStatus":
        let finalValue = value;
        if (this.app.customers.expectCurrent === Customer.DB) {
          /** treat special cases where the case number is a string */
          if (value === "90a") {
            finalValue = "91";
          }
          if (value === "90b") {
            finalValue = "92";
          }
          if (value === "90c") {
            finalValue = "93";
          }
          return Number(finalValue);
        }
      default:
        return this.app.field.getFieldValueAsText(field, value);
    }
  }

  setHeaderTooltip(column: string) {
    let fieldId = column.split(".")[1];
    switch (fieldId) {
      case "sapStatus":
        return this.app.field.getLabel("part." + fieldId);
      default:
        return "";
    }
  }
}
