import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { model2Doc, updateUser } from "../api.service";
import { passwordPolicy } from "./password-policy";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit {
  passwordPolicy = passwordPolicy;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    if (this.app.user) {
      await this.app.users.getUserById(this.app.user);
    }
  }

  get requiredFields() {
    this.app.field.resetAllRequiredFieldSettings("user");
    this.app.field.getFieldSettings("user.name").required = false;
    this.app.field.getFieldSettings("user.roles").required = false;
    this.app.field.getFieldSettings("user.oldPassword").required = true;
    this.app.field.getFieldSettings("user.password").required = true;
    this.app.field.getFieldSettings("user.retypePassword").required = true;
    let requiredFields = this.app.field.getInvalidFields(
      "user",
      this.app.model
    );
    return requiredFields;
  }

  async save() {
    const { model, user } = this.app;

    if (user == null) {
      throw new Error("user name == null");
    }
    if (this.app.user == null) {
      throw new Error("user not found");
    }

    let result: any = {};
    let currentUser = this.app.users.currentUser;
    const currentPassDoc = model2Doc("user", model);

    // Update password expiry date on update of password
    const d = new Date();
    d.setMonth(d.getMonth() + 6);
    const nextExpireDate = d.toISOString().slice(0, 10);

    currentUser.password = currentPassDoc.password;
    currentUser.oldPassword = currentPassDoc.oldPassword;
    currentUser.retypePassword = currentPassDoc.retypePassword;
    currentUser.passwordExpireDate = nextExpireDate;

    let passwordPolicy = this.app.users.checkPasswordPolicy(currentUser);

    if (passwordPolicy === "No error") {
      const passwordValid = await this.app.auth.verifyOldPassword(
        user,
        currentPassDoc.oldPassword
      );

      if (!passwordValid) {
        this.app.hasError = true;
        this.app.errorText = this.app.text.profilePassword.oldPasswordIncorrect;
      }

      if (passwordValid) {
        currentUser.forcePasswordChangeOnFirstLogin = false;

        result = await updateUser(
          this.app.customers.expectCurrent,
          currentUser
        );

        if (!result.error) {
          this.app.unlockedId = null;
          this.app.hasSuccess = true;
          this.app.hasError = false;
          this.app.successText =
            this.app.text.profilePassword.succesUserUpdated;
          this.app.routing.navigateProfile(user);
          this.app.clearModel();
        } else {
          this.app.hasSuccess = false;
          this.app.hasError = true;
          this.app.errorText = this.app.text.user.passwordUsed;
        }
      }
    } else {
      return;
    }
    if (!result.error && !this.app.hasError) {
      this.app.users.currentUser = result;
    }
  }

  discardChanges() {
    if (this.app.user == null) {
      return;
    }

    this.app.routing.navigateProfile(this.app.user);
  }
}
