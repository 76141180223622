<app-modal
  #enlargeImagedModal
  [showButtons]="false"
  [imageUrl]="imageSource"
  [modalType]="'image'"
></app-modal>
<app-modal
  #deleteImageModal
  [modalMessage]="app.text.thread.deleteImage"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.leftNav.deleteImage(docType, file)"
>
</app-modal>

<!-- Image -->
<div class="image-container" *ngIf="showImage">
  <img class="nav-image img-fluid" [src]="imageSource" />
  <div class="image-buttons">
    <button
      class="btn"
      *ngIf="showImageControlBtn"
      (click)="enlargeImagedModal.open()"
    >
      <i class="fas fa-expand-arrows-alt icon-orange"></i>
    </button>
    <button
      class="btn pull-right"
      *ngIf="showImageControlBtn"
      (click)="deleteImageModal.open()"
      [disabled]="app.unlockedId != null"
    >
      <i class="fa fa-trash icon-orange"></i>
    </button>
  </div>
  <div class="col-sm-12 text-center" *ngIf="lastUpdatedOn">
    <span class="labelRow p-1"
      >{{ app.field.getLabel("manufacturer.lastUpdatedOn") }}:</span
    >
    <span class="labelRow"
      >{{ app.field.getLabel("manufacturer.lastUpdatedOn") }}:
      {{ lastUpdatedOn }}</span
    >
    <span class="textRow">{{ lastUpdatedOn }}</span>
  </div>
</div>

<div
  class="box-header mt-3 mb-0 px-1 py-2"
  *ngIf="showBoxes"
  [ngClass]="{
    editModeBorder: app.thread.isEditMode() && app.view === 'thread'
  }"
>
  <div class="row mx-1">
    <div
      class="p-0"
      [ngClass]="{
        'col-md-8 col-lg-8 col-xl-8': app.view === 'thread' && app.thread.isNew,
        'col-sm-6 col-md-7 col-lg-6 col-xl-8': app.view === 'part'
      }"
    >
      <div class="btn-box m-0 p-1">
        <button
          class="btn btn-nav overrideButton ms-0 p-1"
          href="#"
          *ngFor="let box of listBoxes; let i = index"
          (click)="
            app.leftNav.selectedBox = box; app.leftNav.selectedBoxInit(box)
          "
          [ngClass]="{
            'btn-nav-active': box === app.leftNav.selectedBox,
            'btn-nav-active-rm':
              box === app.leftNav.selectedBox &&
              (app.view === 'part' || app.view === 'manufacturer')
          }"
        >
          {{ app.getText(box) }}
          <span
            *ngIf="app.unlockedId != null && isSectionInvalid(box)"
            class="hasRequiredField"
            ><i class="fal fa-exclamation-circle"></i
          ></span>
        </button>
      </div>
    </div>

    <div
      *ngIf="app.view === 'thread' && app.thread.isNew"
      class="p-0"
      [ngClass]="
        app.unlockedId != null
          ? 'col-md-4 col-lg-4 col-xl-4'
          : 'col-md-2 col-lg-2 col-xl-2'
      "
    >
      <app-case-controls></app-case-controls>

      <!-- <div *ngIf="!app.thread.isNew" class="clear pull-right">
        <button
          *ngIf="app.thread.showMore"
          class="btn btn-toggle"
          [title]="app.text.app.narrow"
          (click)="app.thread.hidePost()"
        >
          <i class="fal fa-chevron-double-left icon-toggle"></i>
        </button>
        <button
          *ngIf="!app.thread.showMore"
          class="btn btn-toggle"
          [title]="app.text.app.expand"
          (click)="app.thread.hidePost()"
        >
          <i class="fal fa-chevron-double-right icon-toggle"></i>
        </button>
      </div> -->
    </div>
  </div>
</div>
