<!-- fileInput.value = null is used when re-select the same file to be uploaded -->
<input
  #fileInput
  type="file"
  accept="*"
  style="display: none"
  (click)="fileInput.value = null"
  (change)="changeFileInput()"
  multiple="true"
/>
<button
  class="btn btn-sm btn-default-orange"
  *ngIf="!app.auth.hasMTUPermission"
  (click)="fileInput.click()"
  [title]="app.text.attachments.upload"
>
  <i class="fa fa-upload"></i>
</button>

<!-- open a warning modal is the current user has MTU role -->
<button
  class="btn btn-sm btn-default-orange"
  *ngIf="app.auth.hasMTUPermission"
  (click)="OMWarningModal.open()"
  [title]="app.text.attachments.upload"
>
  <i class="fa fa-upload"></i>
</button>

<app-modal
  #OMWarningModal
  [modalMessage]="app.text.attachments.OMWarning"
  [modalTitle]="app.text.thread.modalTitle"
  [showOMWarning]="true"
  (ok)="fileInput.click()"
>
</app-modal>
