import { APP_TYPE, AppType } from "../app.type";
import { Docs } from "../../../../shared/components";
import {
  doc2Model,
  getProfile,
  getProfiles,
  model2Doc,
  updateProfile,
  updateProfileFavorite,
} from "../api.service";
import { Inject, Injectable } from "@angular/core";
import { Profile } from "../../../../shared/models/profile";
import {
  ProfileField,
  ProfileServiceType,
  ProfileWithSections,
} from "./profile.service.type";
import { BehaviorSubject } from "rxjs";
import { loadModel } from "../utils/app.utils";

@Injectable()
export class ProfileService implements ProfileServiceType {
  profiles: Profile[] = [];
  ids: string[] = [];
  profile: Profile = {} as Profile;
  ownProfile: Profile = {} as Profile;
  profileWithSections: ProfileWithSections = {} as ProfileWithSections;
  cleanProfileWithSections: ProfileWithSections = {} as ProfileWithSections;
  ownProfilePicture: string | null = "";
  userProfilePicture: string | null = "";
  ownProfilePictureChanges: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get user() {
    return this.app.state.profileUser;
  }

  async getProfiles() {
    this.profiles = await getProfiles(this.app.customers.expectCurrent);
    this.ids = this.profiles.map((p) => p.id);

    return this.profiles;
  }

  async getProfile(user = this.app.user): Promise<Profile> {
    if (user == null) {
      return {} as Profile;
    }

    this.profile = await getProfile(user);

    return this.profile;
  }

  loadProfileModelWithSections() {
    this.profileWithSections = doc2Model(
      "profile",
      this.profile
    ) as ProfileWithSections;
    loadModel("profile", this.profile, this.profileWithSections);

    if (this.profile.sections) {
      loadModel(
        "personProfile",
        this.profile.sections.pers,
        this.profileWithSections
      );
      loadModel(
        "organizationProfile",
        this.profile.sections.org,
        this.profileWithSections
      );
    }

    Object.assign(this.cleanProfileWithSections, this.profileWithSections);
  }

  async getProfilePicture(user: string | null) {
    if (user == null) {
      return null;
    }

    let fileLink = "";
    const customer = this.app.customers.expectCurrent;
    const ownProfile = this.app.user === user ? true : false;
    const attachments = await this.app.file.getAttachmentsByDocType(
      user,
      "profile"
    );

    // If no files were attached to the profile, an object which has the length property 0 will be returned
    if (Object.keys(attachments).length === 0) {
      if (ownProfile) {
        this.ownProfilePicture = null;
      }
    } else {
      const fileName = Object.keys(attachments)[0];
      fileLink = [customer, "profile", user, fileName].join("/");

      if (ownProfile) {
        this.ownProfilePicture = fileLink;
      }
    }

    return fileLink;
  }

  async updateProfile() {
    const personProfile = model2Doc("personProfile", this.profileWithSections);
    const organizationProfile = model2Doc(
      "organizationProfile",
      this.profileWithSections
    );

    // Encode the profile sections in a JSON string to be saved as the 'data'
    this.profile.data = this.getProfileDataJson(
      personProfile,
      organizationProfile
    );
    delete this.profile.sections;

    await updateProfile(this.profile);
    await this.app.userSettings.updateSettings();
    await this.getProfile(this.profile.id);
    this.loadProfileModelWithSections();
  }

  async updateMultipleFavorite(
    profileIds: string[],
    customer = this.app.customers.expectCurrent,
    threadId: string,
    toggle: boolean
  ): Promise<void> {
    await updateProfileFavorite(profileIds, customer, threadId, toggle);
  }

  async uploadProfilePicture(file: File) {
    const { customer, user } = this.app;

    if (customer == null) {
      return;
    }

    if (user == null) {
      return;
    }

    if (file != null && file.type.indexOf("image/") !== 0) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.thread.uploadImageTypeError;
    } else {
      this.app.hasError = false;
      this.app.errorText = "";

      let fileLink = [customer, "profile", btoa(user), "user.jpg"].join("/");
      await this.app.file.upload(file, fileLink);

      fileLink = [customer, "profile", user, "user.jpg"].join("/");
      this.ownProfilePictureChanges.next(fileLink);
      this.userProfilePicture = fileLink;
    }
  }

  async deleteProfilePicture() {
    const { customer, user } = this.app;

    if (customer == null || user == null) {
      return;
    }

    /** !!! DELETE any attachment that has been added to the profiles independent on the name !!!  */
    const fileLink = [customer, "profile", btoa(user), "user.jpg"].join("/");
    await this.app.file.delete(fileLink);

    this.ownProfilePictureChanges.next("");
    this.userProfilePicture = "";
  }

  private getProfileDataJson(
    pers: Docs["personProfile"],
    org: Docs["organizationProfile"]
  ): string {
    const persFieldList: ProfileField[] = [];
    const orgFieldList: ProfileField[] = [];

    Object.keys(pers)
      .filter((key) => key !== "publicFields")
      .forEach((key) => {
        persFieldList.push({
          title: key,
          value: (pers as any)[key],
        });
      });

    Object.keys(org)
      .filter((key) => key !== "publicFields")
      .forEach((key) => {
        orgFieldList.push({
          title: key,
          value: (org as any)[key],
        });
      });

    const profileData = {
      pers: persFieldList,
      org: orgFieldList,
    };

    return JSON.stringify(profileData);
  }
}
