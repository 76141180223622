<div class="card mt-3">
  <div class="card-header p-2">
    {{ app.text.userSettings.emailPreferences }}
  </div>
  <div>
    <div
      class="email-option col-sm-6 col-md-4 col-xl-3"
      *ngFor="let email of customerEmailsList"
    >
      <button
        class="btn btn-sm checkbox"
        (click)="app.userSettings.addSelectedOptions(email)"
        [ngClass]="{ disabled: app.unlockedId == null }"
      >
        <i
          *ngIf="!app.userSettings.currentUserEmailPreferences.includes(email)"
          class="far fa-square"
        ></i>
        <i
          *ngIf="app.userSettings.currentUserEmailPreferences.includes(email)"
          class="far fa-check-square"
        ></i>
        <label class="fieldName" for="email">{{ getText(email) }}</label>
      </button>
    </div>
  </div>
</div>
