<div class="filter-table" [ngSwitch]="app.filterTable.getFilterType(fieldId)">
  <div *ngSwitchCase="'options'">
    <div class="input-group-btn">
      <div
        class="dropdown"
        [ngClass]="{
          'background-color':
            searchWords[column] != null && searchWords[column] !== ''
        }"
      >
        <input
          class="form-control dropdown-filter dropdown-toggle"
          data-bs-toggle="dropdown"
          [(ngModel)]="searchWords[column]"
          placeholder="{{ app.text.RM.filter }}"
          onkeydown="return false"
          (click)="
            app.filterTable.isDropdownOpen = true;
            app.filterTable.filterFocus = true
          "
        />
        <button class="btn clear-dropdown">
          <i
            *ngIf="searchWords[column]"
            class="fa fa-times icon-size"
            (click)="clearFilter(column)"
          ></i>
        </button>
        <div class="dropdown-menu dropdown-list">
          <div *ngFor="let option of app.filterTable.getOptions(fieldId)">
            <span
              *ngIf="!app.filterTable.getOptionsAsValue(column)"
              class="dropdown-item"
              (click)="
                searchWords[column] = app.filterTree.getOptionText(
                  fieldId,
                  option
                );
                onChange(column);
                saveFilterInput(column);
                app.filterTable.isDropdownOpen = false
              "
            >
              {{ app.filterTree.getOptionText(fieldId, option) }}
            </span>
            <span
              *ngIf="app.filterTable.getOptionsAsValue(column)"
              class="dropdown-item"
              (click)="
                searchWords[column] = option;
                onChange(column);
                saveFilterInput(column);
                app.filterTable.isDropdownOpen = false
              "
            >
              {{ option }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div
      class="input-group"
      [ngClass]="{
        'background-color':
          searchWords[column] != null && searchWords[column] !== ''
      }"
    >
      <input
        type="text"
        class="form-control input-filter"
        placeholder="{{ app.text.RM.filter }}"
        [(ngModel)]="searchWords[column]"
        (input)="onChange(column)"
        placeholder="{{ app.text.RM.filter }}"
        on-focusout="saveFilterInput(column)"
        (click)="
          app.filterTable.isDropdownOpen = false;
          app.filterTable.filterFocus = true
        "
      />
      <span
        class="input-group-text clearIcon"
        id="basic-addon2"
        *ngIf="searchWords[column]"
        (click)="clearFilter(column)"
        ><i class="fa fa-times"></i
      ></span>
    </div>
  </div>
</div>
