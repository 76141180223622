import { _ComponentSettings, ComponentSettings } from "./settings/settings";
import { _import } from "./components/import.settings";
import { alert } from "./components/alerts.settings";
import { app } from "./components/app.settings";
import { applicationArea } from "./components/application-area.settings";
import { attachment } from "./components/attachment.settings";
import { attachments } from "./components/attachments.settings";
import { auth } from "./components/auth.settings";
import { change } from "./components/change.settings";
import { commodityGroupResponsible } from "./components/commodity-group-responsible.settings";
import { core } from "./components/core.settings";
import { credits } from "./components/credits.settings";
import { debug } from "./components/debug.settings";
import { dinCodeResponsible } from "./components/din-code-responsible.settings";
import { error } from "./components/error.settings";
import { example } from "./components/example.settings";
import { field } from "./components/field.settings";
import { filter } from "./components/filter-list.settings";
import { footer } from "./components/footer.settings";
import { graphs } from "./components/graphs.settings";
import { history } from "./components/history.settings";
import { home } from "./components/home.settings";
import { impact } from "./components/impact.settings";
import { internalItem } from "./components/internal-items.settings";
import { mail } from "./components/mail.settings";
import { manufacturer } from "./components/manufacturer.settings";
import { matchingManufacturer } from "./components/manufacturer-matching.settings";
import { matchingManufacturerModal } from "./components/manufacturer-matching-modal.settings";
import { message } from "./components/message.settings";
import { modal } from "./components/modal.settings";
import { next } from "./components/next.settings";
import { operations } from "./components/operations.settings";
import { organizationProfile } from "./components/organization-profile.settings";
import { part } from "./components/part.settings";
import { pcn } from "./components/pcn.settings";
import { pdf } from "./components/pdf.settings";
import { person } from "./components/person.settings";
import { personProfile } from "./components/person-profile.settings";
import { post } from "./components/post.settings";
import { profile } from "./components/profile.settings";
import { profilePassword } from "./components/password.settings";
import { resetPassword } from "./components/reset-password.settings";
import { responsibles } from "./components/responsibles.settings";
import { settings } from "./components/settings.settings";
import { stepper } from "./components/stepper.settings";
import { stock } from "./components/stock.settings";
import { SVHCItems } from "./components/SVHC-items.settings";
import { sync } from "./components/sync.settings";
import { table } from "./components/table.settings";
import { thread } from "./components/thread.settings";
import { threadItem } from "./components/thread-item.settings";
import { totalCostLTB } from "./components/totalCostLTB.settings";
import { train } from "./components/trainseries.settings";
import { user } from "./components/user.settings";
import { vehicleResponsible } from "./components/vehicle-responsible.settings";
import { sidebar } from "./components/sidebar.settings";
import { productCategory } from "./components/product-category.settings";
import { RM } from "./components//RM.settings";
import { pcnGenerator } from "./components/pcn-generator.settings";
import { userSettings } from "./components/user-settings.settings";
import { manufacturerName } from "./components/manufacturer-name.settings";
import { manufacturerCode } from "./components/manufacturer-code.settings";
import { parameter } from "./components/parameter.settings";

/**
 * Settings for each component must be added to this object
 */
const components = {
  app,
  core,
  attachment,
  attachments,
  auth,
  resetPassword,
  field,
  footer,
  history,
  graphs,
  credits,
  next,
  filter,
  home,
  table,
  sync,
  settings,
  debug,
  import: _import,
  thread,
  threadItem,
  post,
  change,
  impact,
  part,
  manufacturer,
  user,
  userSettings,
  person,
  profilePassword,
  profile,
  personProfile,
  organizationProfile,
  commodityGroupResponsible,
  dinCodeResponsible,
  vehicleResponsible,
  stepper,
  example,
  modal,
  pdf,
  pcn,
  error,
  RM,
  message,
  mail,
  alert,
  matchingManufacturer,
  matchingManufacturerModal,
  train,
  applicationArea,
  pcnGenerator,
  operations,
  stock,
  totalCostLTB,
  internalItem,
  responsibles,
  SVHCItems,
  sidebar,
  productCategory,
  manufacturerName,
  manufacturerCode,
  parameter,
};

// SETTINGS

export type Definition = typeof components;

export type Type = keyof Definition;

export const types: Type[] = Object.keys(components) as any;

export type Settings = {
  [key in Type]: ComponentSettings<Definition[key]>;
};

export const defaultSettings: Settings = components;

// DOCS

export type Doc<T> = {
  [name in keyof T]: any;
} & {
  _id?: string;
  _rev?: string;
  _deleted?: boolean;
};

export type Docs = {
  [name in keyof Settings]: Doc<Settings[name]["field"]>;
};

export function getFieldList(name: Type) {
  const { field } = components[name] as _ComponentSettings;
  return Object.keys(field);
}

export function isDocType(name: Type) {
  return getFieldList(name).length > 0;
}

export function getDocTypes() {
  return Object.keys(components)
    .map((name: any) => name as Type)
    .filter((name) => isDocType(name));
}
