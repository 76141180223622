<app-modal
  #deleteDocsModal
  [modalMessage]="app.text.table.deleteNotification"
  [modalTitle]="app.text.alert.modalTitle"
  (ok)="deleteChangesDocs()"
>
</app-modal>
<app-paginator
  [length]="app.change.changes.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<div class="row" *ngIf="app.table.selected.size > 0">
  <div class="col-md-4 ps-1">
    <div class="d-inline-flex selectedAlerts">
      <a
        class="me-3 pointer"
        *ngIf="app.table.selected.size > 0"
        (click)="markItems()"
      >
        <i class="fas fa-check-double fa-green"></i> &nbsp;
        {{ app.text.change.markAsSeen }}
      </a>
      <a
        class="pointer"
        *ngIf="app.table.selected.size > 0"
        (click)="deleteDocsModal.open()"
      >
        <i class="fas fa-trash"></i> &nbsp;
        {{ app.text.alert.deleteAllSelected }}
      </a>
    </div>
  </div>
  <div class="col-md-4 text-center">
    <div class="text-center d-inline-flex selectedAlerts">
      <span> {{ app.table.selected.size }} {{ app.text.mail.selected }} </span>
      <a
        class="selectAllButtton"
        (click)="app.table.selectAll(app.change.changes, true)"
        *ngIf="app.table.selected.size !== app.change.changes.length"
      >
        {{ app.text.mail.selectAll }} {{ app.change.changes.length }}
      </a>
      <a
        class="selectAllButtton"
        *ngIf="app.table.selected.size === app.change.changes.length"
        (click)="app.table.clearSelection()"
      >
        {{ app.text.mail.deselectAll }}
      </a>
    </div>
  </div>
</div>
<div>
  <div
    class="tableFixHead"
    [ngClass]="{
      'table-responsive-style': app.customers.expectCurrent === 'db',
      tableHeight: app.filterTable.isDropdownOpen
    }"
  >
    <table class="table table-striped spacing-table">
      <thead>
        <tr>
          <th class="p-1" *ngFor="let column of columns">
            <div class="d-inline-flex">
              <div
                [ngClass]="{
                  'resize-table': app.table.canColumnBeResized(column)
                }"
              >
                <span
                  (click)="
                    app.table.toggleSortDirection(column);
                    app.table.sort(app.change.changes)
                  "
                >
                  {{ app.field.handleSpecialLogicLabels(column) }}
                </span>
              </div>
              &nbsp;
              <span *ngIf="column === app.table.sortField">
                <i
                  *ngIf="app.table.sortDirection === 1"
                  class="fa fa-chevron-up"
                ></i>
                <i
                  *ngIf="app.table.sortDirection === -1"
                  class="fa fa-chevron-down"
                ></i>
              </span>
            </div>
            <div *ngIf="column === 'select'">
              <button
                class="btn btn-hidden pt-0 ps-0"
                *ngIf="
                  app.table.selected.size === app.change.changes.length &&
                  app.change.changes.length
                "
                (click)="app.table.clearSelection()"
              >
                <i class="far fa-check-square"></i>
              </button>
              <button
                class="btn btn-hidden pt-0 ps-0"
                *ngIf="app.table.selected.size === 0"
                (click)="app.table.selectAll(app.change.changes)"
              >
                <i class="far fa-square"></i>
              </button>
            </div>
            <div *ngIf="app.filterTable.getFilterTableColumns(column)">
              <app-filter-table
                [column]="app.mail.getColumnShort(column)"
                [columns]="columnsShort"
                [docType]="'change'"
                [cleanDocs]="app.change.cleanChanges"
                [selectedFilters]="selectedFilters"
                (resultedDocs)="
                  app.filterTable.getFilterResults($event, 'change')
                "
              >
              </app-filter-table>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="p-1"
          *ngFor="let change of app.paginator.getPage(app.change.changes)"
          [ngClass]="{ read: change.seen }"
          class="pointer"
          (click)="
            app.mail.markItemsAsSeen('seen', change);
            app.thread.navigateThreadByOmfNumber(change.omfNumber)
          "
        >
          <td class="p-1" *ngFor="let column of columnsShort">
            <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
              <div *ngSwitchCase="'time'">
                {{ change[column] | date : "yyyy-MM-dd HH:mm:ss" }}
              </div>
              <div *ngSwitchCase="'normal'">
                {{ change[column] }}
              </div>
              <div *ngSwitchCase="'artNumber'">
                {{ change[column] }}
              </div>
              <div *ngSwitchCase="'optionText'">
                <span>
                  {{
                    app.field.getOptionText("change." + column, change[column])
                  }}
                </span>
              </div>
              <div *ngSwitchCase="'actions'">
                <div *ngIf="column === 'seen'">
                  <button
                    class="btn btn-hidden pt-0 ps-0"
                    *ngIf="change.update_user !== undefined && change[column]"
                    [ngbTooltip]="
                      change.update_user +
                      ' ' +
                      app.text.credits.on +
                      ' ' +
                      (change.update_time | date : 'yyyy-MM-dd')
                    "
                  >
                    <i class="fas fa-check-double fa-green"></i>
                  </button>
                  <button
                    class="btn btn-hidden pt-0 ps-0"
                    *ngIf="change.update_user == undefined && change[column]"
                  >
                    <i class="fas fa-check-double fa-green"></i>
                  </button>
                  <button
                    class="btn btn-hidden pt-0 ps-0"
                    *ngIf="!change[column]"
                  >
                    <i class="fas fa-exclamation-circle fa-orange"></i>
                  </button>
                </div>
                <div *ngIf="column === 'select'">
                  <button
                    class="btn btn-hidden pt-0 ps-0"
                    *ngIf="!change[column]"
                    (click)="
                      app.table.toggleSelect(change); $event.stopPropagation()
                    "
                  >
                    <i
                      class="far fa-check-square"
                      *ngIf="app.table.selected.has(change)"
                    ></i>
                    <i
                      class="far fa-square"
                      *ngIf="!app.table.selected.has(change)"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td *ngIf="loading" colspan="99" class="text-center">
            {{ this.app.text.core.loading }}
          </td>
          <td
            *ngIf="!loading && app.change.changes.length === 0"
            colspan="99"
            class="text-center"
          >
            {{ app.text.app.noResults }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-paginator
  [length]="app.change.changes.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
