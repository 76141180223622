<span *ngIf="app.customer == 'omp'">
  <div *ngFor="let url of app.list.profile.urls">
    <a [href]="getOMPDocumentationURLs(url)" target="_blank">
      {{ app.getText(url) }}
    </a>
  </div>
</span>
<div class="mt-4">
  <div *ngFor="let url of links">
    <div *ngIf="app.customer !== 'coop'">
      <span *ngIf="url.OM">
        <a target="_blank" [href]="url.OM">
          {{ app.text.profile.userOMManual }}
        </a>
      </span>

      <span *ngIf="url.RM">
        <a target="_blank" [href]="url.RM">
          {{ app.text.profile.userRMManual }}
        </a>
      </span>
    </div>
  </div>
</div>
